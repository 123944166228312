<template>
  <div class="text-center">

    <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        max-width="400px"


    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          {{title}}
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {

  props:['title','dialog'],

}
</script>

<style scoped>


</style>