import axios from 'axios';

import configraions from '@/configuration/dbConfig'
const url =  `${configraions.url}/api/v1`;


import store from '@/store/index.js';
import router from "@/router";
import PermissionsManagement from "@/services/PermissionsManagement";



const  GetData = async(name) =>{
        // console.log(store.state.token)
    try {
        const response = await axios.get(url+name,  {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+store.state.token,
            }
        });
        if(response.status==200){
            return  await response.data;

        }else if(response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(response.status==403) {
            await PermissionsManagement.initPermissionRole();
            router.push('/');
        }else {
            console.error("Axios error: ");
            return  "false";
        }

    } catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }
}
const  GetDataDevice = async(name,device_token) =>{

    try {
        const response = await axios.get(url+name,  {
            headers: {

                'Content-Type': 'application/json',
                'authorization': "Bearer "+device_token,

            }});

        if(response.status==200){
            return  await response.data;

        }else {
            console.error("Axios error: ");
            return  "false";
        }

    } catch (err) {
        console.error("Axios error: ", err);

        return  "false";
    }
}
const  GetDataCustom = async(name,device_token) =>{

    try {
        const response = await axios.get(configraions.url+name,  {
            headers: {

                'Content-Type': 'application/json',
                'authorization': "Bearer "+device_token,

            }});

        if(response.status==200){
            return  await response.data;

        }else {
            console.error("Axios error: ");
            return  "false";
        }

    } catch (error) {
         if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }
}
const  PutUpdateData = async(name,values) =>{

    try {
        const response = await axios.put(url+name, values,{
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+store.state.token,
            }
        });

        if(response.status==200){
            return  await response.data;
        }else if(response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else {
            console.error("Axios error: ");
            return response ;
        }

    } catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }

}
const  GetPostData = async(name,values) =>{

    try {
        const response = await axios.post(url+name, values,{
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+store.state.token,
            }
        });

        if(response.status==200){
            return  await response.data;
        }else if(response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else {
            console.error("Axios error: ");
            return response ;
        }

    } catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }

}
const  sendTextWhatsapp = async(values,device_token) =>{

    try {


      const response = await axios.post(url+"/send-message", values,{
            headers: {

                'Content-Type': 'application/json',
                'authorization': "Bearer "+device_token,

            }});



        if(response.status==200){

            return  await response.data;
        }
        else if(response.status==401){
            return  {status:false,
            message:"الجهاز غير مسجل"}
        }
        else
        return  await response.data;

    } catch (err) {



        return  {status:false,

            message:"فشل غير متوقع"

        }
    }

}

const  sendMediaWhatsapp = async(values, onUploadProgress,device_token) =>{

    try {
        let formData = new FormData();

        formData.append("file", values.file);
        formData.append("message", values.message);
        formData.append("number", values.number);
        formData.append("type", "media");
        const response = await axios.post(url+"/send-media", values,{
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'authorization': "Bearer "+device_token,

            },onUploadProgress});


        if(response.status==200){

            return  await response.data;
        }
        else if(response.status==401){
            return  {status:false,
                message:"الجهاز غير مسجل"}
        }

        return  await response.data;

    } catch (err) {



        return  {status:false,

            message:"فشل غير متوقع"

        }
    }

}
const  createDevice = async(values) =>{

    try {

        const response = await axios.post(url+"/device/create", values,  {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer '+store.state.token,
        });

        if(response.status==200&&response.data.status){

            return  await response.data;
        }
        else{
            return  {status:false,
                message:"فشل غير متوقع"}
        }



    }catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }

}
const  deleteItem = async(name,item,token) =>{


    try {

        const response = await axios.delete(url+name+"/"+item,{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': "Bearer "+token,
            }});

        if(response.status==200&&response.data.status){

            return  await response.data;
        }
        else{
            return {status:false,
                message:"فشل غير متوقع"}
        }



    } catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }

}
const  aproveItem = async(name,item,value,token) =>{


    try {

        const response = await axios.put(url+name+"/"+item,value,{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': "Bearer "+token,
            }});

        if(response.status==200&&response.data.status){

            return  await response.data;
        }
        else{
            return {status:false,
                message:"فشل غير متوقع"}
        }



    } catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }

}
const  createGroup = async(values,device_token) =>{

    try {
        const response = await axios.post(url+"/group/create", values ,{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': "Bearer "+device_token,

            }});

        if(response.status==200&&response.data.status){

            return  await response.data;
        }
        else{
            console.log(response.data);
            return  {status:false,
                message:"فشل غير متوقع"}
        }



    } catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();

            router.push('/');
        }else {

            return  "false";
        }

    }

}
const  changePassword = async(values) =>{

    try {

        const response = await axios.post (url+"/changepassword", {username:store.state.user,password:values.password,new_password:values.new_password},{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer '+store.state.token,
        });
        if(response.status==200){

            return  await response.data==true;
        }else if(response.status==401){
            store.dispatch('logout');
            router.push('login');
        }else {
            console.error("Axios error: ");
            return  false;
        }

    } catch (error) {

        if(error.response.status==401){
            store.dispatch('logout');
            router.push('login');
        }
        else if(error.response.status==403) {
            await PermissionsManagement.initPermissionRole();
            router.push('/');
        }else {

            return  "false";
        }

    }

}
export default {
    GetData,
    GetPostData,
    changePassword,
    sendTextWhatsapp,
    createDevice,
    sendMediaWhatsapp,
    GetDataDevice,
    deleteItem,
    GetDataCustom,
    createGroup,
    aproveItem,
    PutUpdateData

};