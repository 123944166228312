

import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    token: '',
    roles: {},
    user: {}
  };
};

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {

    hasCreatePermission:(state) => (container) =>{

      return state.roles.hasOwnProperty(container)?(state.roles[container]).hasOwnProperty("add"):false;
    },
    hasViewPermission:(state) => (container) =>{

      return state.roles.hasOwnProperty(container)?(state.roles[container]).hasOwnProperty("view"):false;
    },
    hasChangePermission:(state) => (container) =>{
      return state.roles.hasOwnProperty(container)?(state.roles[container]).hasOwnProperty("change"):false;
    },
    hasDeletePermission:(state) => (container) =>{
      return state.roles.hasOwnProperty(container)?(state.roles[container]).hasOwnProperty("delete"):false;
    },
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_ROLES: (state, roles) => {

      state.roles = roles;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    login: ({ commit,  }, { token, user }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    setRoles: ({ commit,  }, roles) => {

      commit('SET_ROLES', roles);
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    }
  }
});