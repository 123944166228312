<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="جاري تغيير كلمة المرور"

    />
    <v-dialog v-model="dialog" max-width="40%"  >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="white" elevation="0" v-bind="attrs" v-on="on">تغيير كلمة المرور</v-btn>
      </template>

      <v-card >
        <v-card-title>
          <span class="text-h5">تغيير كلمة المرور</span>
        </v-card-title>
        <v-card-text>

          <v-container >
            <v-row >
              <v-col>
                <v-card ref="form" elevation="0">
                  <v-card-text>
                    <v-text-field
                        ref="oldPassword"
                        outlined
                        dense
                        color="rgb(200, 200, 200)"
                        filled
                        v-model="oldPassword"
                        label="كلمة المرور القديمة"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        value=""
                        :rules="[() => !!oldPassword || 'يجب ادخال كلمة المرور القديمة']"
                        :error-messages="isShow?msgError:null"

                        class="input-group--focused"
                        @click:append="show1 = !show1"
                    ></v-text-field>
                    <v-text-field
                        ref="newPassword"
                        outlined
                        dense
                        filled
                        color="rgb(200, 200, 200)"

                        label="كلمة المرور الجديدة"
                        v-model="newPassword"
                        :rules="[() => !!oldPassword || 'يجب ادخال كلمة المرور الجديدة']"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        value=""
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                    ></v-text-field>
                    <v-text-field
                        ref="confirmPassword"
                        outlined
                        dense
                        filled
                        color="rgb(200, 200, 200)"
                        label="تأكيد كلمة المرور الجديدة"
                        v-model="confirmPassword"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show3 ? 'text' : 'password'"
                        value=""
                        :rules="[() => !!confirmPassword || 'يجب تأكيد كلمة المرور الجديدة']"
                        :error-messages="(confirmPassword.length>0?newPassword==confirmPassword:true)?null:'كلمة المرور غير صحيحة'"



                        required
                        class="input-group--focused"
                        @click:append="show3 = !show3"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>




            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="!isloading">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog=false"> الغاء </v-btn>
          <v-btn color="blue darken-1" text @click="changePassword()"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
          class="mt-5"
          left
          border="left"
          color="red"
          dismissible
          elevation="9"
          type="error"
          v-model="isShow"
      >{{msgError}}</v-alert>
      <SuccessDialog title="تم تغيير كلمة المرور بنجاح!" is-show="isChangeSucess" v-if="isChangeSucess"/>
    </v-dialog >


  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessDialog.vue';

export default {
  components: {
    Loading,
    SuccessDialog

  },
  data () {
    return {
      form () {
        return {
          oldPassword:this.oldPassword,
          newPassword:this.newPassword,
          confirmPassword:this.confirmPassword
        }
      },

      show1:false,
      show2:false,
      show3:false,
      isloading: false,
      dialog: false,
      oldPassword:"",
      newPassword:"",
      confirmPassword:"",
      isShow: false,
      isChangeSucess: false,
      msgSucess:"تم تغيير كلمة المرور بنحاح",
      msgError:"كلمة المرور القديمة غير صحيحة",

    }
  },

  methods: {

    async changePassword() {
      try {
        if( this.newPassword ==this.confirmPassword)
        {
          this.isloading =true;
          const credentials = {
            'password': this.oldPassword,
            'new_password': this.newPassword
          };

          if(await PushServicesSocket.changePassword(credentials))
          {

            this.isChangeSucess = true;
            this.isloading = false;
          }else{
            this.isShow = true;
            this.isloading =false;
            this.oldPassword = "";
          }

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    }

  },
  async mounted() {

  }
}
</script>

<style scoped>


</style>