import axios from 'axios';
import configraions from '@/configuration/dbConfig'

const url = configraions.url;

export default {
    isLogin(credentials) {
        const token = axios
        .post(url + '/api/v1/auth', credentials)
        .then(response => response.data).catch((errors) => {
            console.log("Cannot log in")
        });
        if(!token){
            this.$session.set("isAuth", token); 
        }

        return token;
    },

    getSecretContent() {
        return axios.get(url + '/').then(response => response.data);
    }
};