<template>
      
    <VApp >
      
      <v-main class="application">
        <SidBar v-if="this.$store.getters.isLoggedIn"></SidBar>
        <router-view></router-view>
    </v-main>
  </VApp>

</template>

<script>
import SidBar from './components/SideBar.vue'
export default {
  name: 'App',


  components: {
    SidBar,
  },
  data: () => ({
    //
  }),
};
</script>


<style lang="scss">
.application {

  font-family: "Vazirmatn";
  font-weight: 550;
  font-size: 1.5rem;


}
@font-face {
  font-family: 'Vazirmatn';

  src: local('Vazirmatn'), url('assets/fonts/Vazirmatn.ttf') format('opentype');

}

</style>