<template>
<nav>
    <v-app-bar   class="flex-grow-0" app order="1"
    elevation="0"
    color="white"
    >
    <template >
        <v-app-bar-nav-icon  @click.stop="drawer = !drawer" >
        </v-app-bar-nav-icon>
    </template>
    <v-spacer></v-spacer>

    <template  >
        <v-menu>


    <template v-slot:activator="{ on, attrs }">
        <v-avatar
        v-bind="attrs"
        v-on="on"
        size="40"
        >
        <img src="../assets/icons/profile.png" alt=""/>
    </v-avatar>
        
    </template>
<v-list>

  <v-list-item>
    <v-list-item-title><v-btn color="white" elevation="0" >مرحباً {{username}}</v-btn></v-list-item-title>
  </v-list-item>
  <v-list-item  v-if="$store.getters.hasCreatePermission('changepassword')">
<ChangePasswordDialog  />
  </v-list-item>
  <v-list-item>
    <v-list-item-title><v-btn color="white" elevation="0" @click.native="exit()">تسجيل خروج</v-btn></v-list-item-title>
  </v-list-item>


    </v-list>


        </v-menu>
    </template>

    </v-app-bar>
        <v-navigation-drawer 
        dark app   right  class="navigation" 
        style="background: #2a2185" 
        
    
    v-model="drawer">

            <v-img :src="require('../assets/icons/logo1.png')" width="70%" class="mr-4  mt-6 mb-4"/>
        <v-list flat  class="">
            <v-list-item-group v-model="selectedItem" style="color: #2a2185">
                <v-list-item v-for="(item, i) in items" :key="i" active-class="border" class="mr-2 " :to="item.to"
                    >
                        <b></b>
                        <b></b>
                    <v-list-item-icon>
                        <v-icon v-text="item.icon" ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.text" style="margin-right: 15px" >  
                    </v-list-item-title>
                    </v-list-item> 
            </v-list-item-group>
        </v-list>

        </v-navigation-drawer>

    </nav>
</template>

<script>
import store from "../store/index.js";
import router from "@/router";
import ChangePasswordDialog from "@/components/dialogs/ChangePasswordDialog";
import PushServicesSocket from "@/services/PushServicesSocket";
import ServicesManager from "@/services/ServicesManager";
export default {
  components:{
    ChangePasswordDialog
  },
  methods:{
    exit(){
     store.dispatch('logout');
     router.push('login');
    },
    async getPermission(){
      this.isloading = true;
      this. permissionContents = await PushServicesSocket.GetData("/permission/contents");

      this.temps.forEach(item => {

        if(this.$store.getters.hasViewPermission(item.value))
          this.items.push(item);

      });


      this.isloading = false;
    },
  },
  data: () => ({
      username: store.state.user,
        selectedItem: 0,
      isloading:false,
      permissionContents:[],
        drawer: true,

    rail: true,
    temps:[

      {
        icon:"mdi-account-outline",
        text:"المستخدمين" ,
        value:"account",
        to: "/accounts",
      },
      {
        icon:"mdi-whatsapp",
        text:"وتساب" ,
        value:"device",
        to: "/whatsapp",
      },
      {
        icon:"mdi-message-outline",
        text:"مجموعات الواتساب" ,
        value:"group",
        to: "/sms",
      },
      {
        icon:"mdi-bell-outline",
        text:"رسائل الاشعارات" ,
        value:"notifications",
        to: "/notifications",
      },
      {
        icon:"mdi-gift-outline",
        text:"عروض الباقات" ,
        value:"product",
        to: "/packages",
      },
      {
        icon:"mdi-gift",
        text:"الباقات" ,
        value:"movement",
        to: "/viewpackages",
      },
      {
      icon:"mdi-file-document-multiple-outline",
      text:"التقارير" ,
      value:"report",
      to: "/reports",
      }


    ],
    items:[  {
      icon:"mdi-file-document-multiple-outline",
      text:"لوحة البيانات" ,
      value:"dashboard",
      to: "/",

    }] ,



}),

   async mounted() {
    await this.getPermission();
    }
}
</script>

<style>

:root {
    --blue: #2a2185;
    --orange: #343434;
    --blueFull: #342e7a;
    --white: #fff;
    --gray: rgb(228, 228, 228);
    --black1: #222;
    --black2: #999;



}

.border 
{
    background: #fff ;
    border-top-right-radius: 16px;
    border-bottom-right-radius:  16px;
    color: #2a2185;
    font-weight: 800;
    text-decoration: none;



}
.border b:nth-child(2)
{
    position: absolute;
    top: -16px;
    height: 16px;
    width: 93.5%;

    background: white;

}
.border b:nth-child(2)::before
{content: '';
    position: absolute;
    top: 0;
    left:  0;
    width: 100%;
    height: 100%;
    background: var(--blue);
    border-bottom-left-radius: 30px;
    background: var(--blue);

}
.border b:nth-child(1)
{
    position: absolute;
    bottom: -16px;
    height: 16px;
    width: 93.5%;
    background: white;


}
.border b:nth-child(1)::before
{
    content: '';
    position: absolute;
    top: 0;
    left:  0;
    width: 100%;
    height: 100%;
    background: var(--blue);
    border-top-left-radius: 30px;
    background: var(--blue);
    
}
.border b:nth-child(1),
.border b:nth-child(2)
{
display: block;
}






</style>