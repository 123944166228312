
import PushServicesSocket from "@/services/PushServicesSocket";
import store from "@/store/index";

const hasViewPermission =  (container)=>{
    return store.getters.hasViewPermission(container);
}
const hasCreatePermission =  (container)=>{
    return store.getters.hasCreatePermission(container);
}
const hasChangePermission =  (container)=>{
    return store.getters.hasChangePermission(container);
}
const hasDeletePermission =  (container)=>{
    return store.getters.hasDeletePermission(container);
}

const initPermissionRole = async ()=>{
    let permissionsRole = {};
    let _temps=   await PushServicesSocket.GetData("/permission/roles");

    if(_temps.status)
    {
        _temps = _temps.data;
        _temps.forEach((values) =>{
            if(permissionsRole.hasOwnProperty(values.container))
            {
                permissionsRole[values.container][values.codename]=values;
                return;
            }

            permissionsRole[values.container] = {[values.codename]:values};
        });
        return   permissionsRole;
    }
    return  {};

}


export default {
    initPermissionRole,
    hasDeletePermission,
    hasCreatePermission,
    hasViewPermission,
    hasChangePermission,

};