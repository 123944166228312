<template>
    <div class="dashboard">
        
        <div class="content">
         
            <router-view/>
        </div>
    </div>
</template>

<script>
import AuthService from '../services/Auth.js';
export default {
    name: 'home',
    data: ()=>({
        isAuth: true,
        user: ""

    }),
   
    async created() {
    if (!this.$store.getters.isLoggedIn) {
    this.$router.push("/login");
    }
    this.user = this.$store.getters.getUser;
 
  },
}
</script>

<style>
.dashboard {
    display: grid;
    background-color: white;
    height: 100%;
    width: 100%;
}

.content {
    background-color: rgb(253, 255, 255);
    border-radius: 10px;
    margin: 6px 6px 6px 0px;
}
</style>