import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from '../views/Dashboard'
import app from "@/App.vue";
import store from '@/store/index.js';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import( '../views/Overview.vue')
  },
  {
    path: '/accounts',
    component: () => import( '../views/Accounts.vue')
  },
  {
    path: '/whatsapp',
    component: () => import( '../views/Whatsapp.vue')
  },
  {
    path: '/sms',
    component: () => import( '../views/Sms.vue')
  },{
    path: '/notifications',
    component: () => import( '../views/Notifications.vue')
  },
  {
    path: '/packages',
    component: () => import( '../views/Packages.vue')
  },
  {
    path: '/viewpackages',
    component: () => import( '../views/ViewPackages.vue')
  },

  {
    path: '/reports',
    component: () => import( '../views/Reports.vue')
  },
  {
    path: '/login',
    component: () => import( '../views/Login.vue')
  },
]



const router = new VueRouter({
  mode: 'history',

  base: process.env.BASE_URL,
  routes
})
router.beforeResolve(async (to, from, next) => {



  if (to.path!="/login" && !store.state.token) {
    next('/login');
    return false;
  }
  if (to.path=="/login" && store.state.token) {
    next('/');
    return false;
  }
  

  next();
});
export default router
