<template>
  <div class="loading">
    <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
    ></v-progress-circular>
  </div>
    
</template>

<script>
export default {


}
</script>

<style scoped>

.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80vh;
  width: 100%;
  font-size: 40px;
  color: rgb(167, 167, 167);
  font-weight: 500;
}
</style>