module.exports = {
  // url: "http://127.0.0.1:9079",
  url: "https://whatsapp.enjazatik.com",
  // urlWS:"ws://localhost:9079"
  urlWS:"wss://whatsapp.enjazatik.com"

  // add_header X-Frame-Options SAMEORIGIN;

};



