
const isExpiredWhatsapp=(date)=>{

    return date!=null&&(parseInt(date.toString()))>(new Date().getTime());
}
const  isAradyDevice = (device) =>{

    try {
        const isExpiredDate =  isExpiredWhatsapp(device.expired_date);
        const isNumber = device.number!="";
        const isQuota =device.quota>0||device.quota_type==='unlimited';
        const isConncected = device.status=="connected";

        return  isExpiredDate&&isQuota&&isNumber&&isConncected;

    } catch (err) {
        return false;
    }

}


export default {
    isAradyDevice,
    isExpiredWhatsapp

};



